import React from 'react'
import Layout from '../../components/Layout'
import Navbar from '../../components/Navbar'
import SEO from "../../components/SEO";

export default function Thanks() {
  return (
    <Layout>
      <SEO title={'Thank You'}></SEO>
      <div className="is-relative">
        <Navbar />
        <section className="section">
          <div className="p-5b p-4-mobile has-background-light pt-5b" style={{ paddingBottom: '35rem', }}>
            <div className="container ml-0 mr-auto pt-5b">
              <div className="content">
                <h1>Thank you!</h1>
                <p>We have received your submission. 
                  {/* If you subscribed to our newsletter, you will receive periodic updates delivered to your inbox. If you offered a new topic to include on this website, thank you. We will be routinely expanding the site with new content. */}
                  </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
